// Bioshop Checkout


.bioshop-antd-drawer{
  &.rs-drawer-right.rs-drawer-sm{
    width: 531px;
  }
  .rs-drawer-dialog{
    .rs-drawer-content{
      .rs-drawer-body{
        padding: 0;
        position: static;
        .rs-drawer-body-close{
          display: none;
        }
      }
    }
  }
  .ant-drawer-header{
    display: none;
  }
  .ant-drawer-body{
    padding: 0;
  }
  .slide-pannel-new{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    .checkout-container{
      height: calc(100vh - 95px);
    }
    .close-slide-panel {
      position: absolute;
      top: 42px;
      left: -45px;
      z-index: 2;
      margin: 0;
      background-color: #fff;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      transition: all 0.5s ease 0s;
      height: 50px;
      padding-right: 0;
      font-size: 20px;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-right: none;
      i {
        position: relative;
        left: 5px;
        font-size: 40px;
      }
    }
    .ant-tabs-top {
      .ant-tabs-nav {
        margin-bottom: 0;
        &:before {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
        .ant-tabs-nav-operations {
          display: none;
        }
      }
    }
    .ant-tabs-nav-wrap {
      width: 100%;
      .ant-tabs-nav-list {
        width: 100%;
        justify-content: space-between;
        .ant-tabs-tab {
          padding: 10px 10px;
          width: 50%;
          justify-content: center;
          box-shadow: 1px 0 0 rgb(0 0 0 / 10%);
          border-top: solid 1px rgb(0 0 0 / 10%);
          margin: 0;
          &:hover {
            color: #010b40;
          }
          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: #010b40;
            }
          }
          .basket-icon {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            position: relative;
            img {
              width: 30px;
              position: absolute;
            }
            .cart-count {
              background: #000;
              width: 15px;
              height: 15px;
              border-radius: 50%;
              position: absolute;
              top: -15px;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 10px;
              z-index: 2;
              font-family: "Nunito Sans", sans-serif !important;
              font-weight: 600;
              left: 8px;
            }
          }
        }
        .ant-tabs-ink-bar {
          background-color: #010b40;
        }
      }
    }
    .prod-for-purchase {
      background-color: #fff;
      height: 49px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding: 10px;
      display: flex;
      width: 100%;
      flex-direction: column;
      position: relative;
      h4 {
        font-size: 1.15rem;
        font-weight: 800 !important;
        padding: 0;
        margin: 0;
      }
      .available-for-purchase {
        font-size: 0.75rem;
        margin-top: 1px;
        color: #757575;
        line-height: 1;
      }
      .go-back {
        position: absolute;
        right: 30px;
        top: 14px;
        display: none !important;
        cursor: pointer;
        &:hover {
          border-bottom: solid 1px #000;
        }
      }
    }
    .pro-list-main {
      height: calc(100vh - 133px);
      position: relative;
      padding-top: 10px;
      h2 {
        font-size: 1.15rem;
        font-weight: 800 !important;
        padding: 0 20px;
      }
      .pro-list-inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        list-style-type: none;
        overflow-y: auto;
        max-height: calc(100% - 0px);
        height: 100%;
        padding: 0 10px;
        .cart-empty {
          padding-left: 10px;
        }

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          transition-duration: 0.3s;
          box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          transition-duration: 0.3s;
          box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
          border-radius: 10px;
        }
        &:hover::-webkit-scrollbar-track {
          box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
        }
        &:hover::-webkit-scrollbar-thumb {
          box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
        }

        .list-col {
          width: 100%;
          display: inline-flex;
          flex-direction: column;
          margin-bottom: 10px;
          &:last-child {
            padding-bottom: 0;
            border-bottom: none;
          }

          .prod-box {
            background-color: #fff;
            display: inline-flex;
            min-width: 100%;
            position: relative;
            border: solid 1px #d9d9d9;
            border-radius: 8px;
            padding: 10px;
            .prod-image {
              width: 65px;
              flex-basis: 65px;
              position: relative;
              overflow: hidden;
              transition: box-shadow 0.25s;
              flex-grow: 0;
              flex-shrink: 0;
              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
            .prod-name {
              display: flex;
              flex-grow: 1;
              margin-left: 25px;
              min-width: 0px;
              flex-direction: column;
              width: 100%;
              padding-right: 15px;
              .prod-title {
                display: flex;
                flex-direction: column;
                .prod-name-inr {
                  font-family: "Nunito Sans", sans-serif !important;
                  font-weight: 700;
                  color: #000;
                  font-size: 0.85rem;
                  line-height: 18px;
                  display: -webkit-box;
                  max-width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                  overflow-wrap: normal;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                }
                .prod-veriants-inr {
                  font-family: "Nunito Sans", sans-serif !important;
                  font-weight: 700;
                  color: #000;
                  font-size: 12px;
                  line-height: 18px;
                }
              }
              .prod-price {
                display: flex;
                margin-top: 5px;
                span {
                  font-family: "Nunito Sans", sans-serif !important;
                  font-weight: 800;
                  color: #000;
                  &.original-price {
                    color: #f00;
                    margin-left: 10px;
                    text-decoration-line: line-through;
                  }
                }
              }
              .qty-rmv-btn {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                margin-top: 5px;
                .remove-btn {
                  color: #000;
                  font-size: 12px;
                  text-decoration: underline;
                }
              }
            }
            .prod-cart-btn {
              display: flex;
              flex-direction: column;
              margin-right: 0;
              gap: 10px;
              a {
                background-color: #010b40;
                border-radius: 37px;
                color: #fff;
                cursor: pointer;
                font-family: Nunito Sans, sans-serif !important;
                font-size: 12px;
                font-weight: 700;
                padding: 6px 0;
                min-width: 80px;
                text-align: center;
                &:hover {
                  background-color: #000;
                }
                &.share-product-oncart{
                  background-color: #FFF;
                  color: #000;
                  border: solid 1px #ccc;
                  &:hover{
                    background-color: #000;
                    color: #FFF;
                  }
                }
              }
            }
          }
        }
        &.list-gap {
          // max-height: calc(100% - 90px);
          max-height: calc(100% - 340px);
        }
      }
      .checkout-buttons {
        display: flex;
        width: 100%;
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px 20px;
        border-radius: 0 0 8px 8px;
        flex-direction: column;
        .grand-total {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 10px;
          .total-price {
            font-family: Nunito Sans, sans-serif !important;
            font-weight: 700;
          }
        }
        button {
          width: 100%;
          padding: 0.7rem 1rem;
          background-color: #010b40;
          border-color: #010b40;
          font-family: Nunito Sans, sans-serif !important;
          font-weight: 700;
          &:hover {
            background-color: #000;
            border-color: #000;
          }
        }


        .checkout-discount-section {
          // display: flex;
          width: 100%;
          gap: 15px;
          margin-top: 10px;
          padding-bottom: 20px;
          border-bottom: solid 1px #d9d9d9;
          margin-bottom: 20px;
          .ant-form {
            width: 100%;
            display: flex;
            gap: 10px;
            .ant-form-item {
              margin-bottom: 0;
            }
            .float-label {
              width: 100%;
              .ant-input {
                height: 45px;
                border-radius: 4px;
              }
            }
            .btn-primary {
              background-color: #010b40;
              border-color: #010b40;
              width: initial;
            }
          }
        }


      }
      &.pro-list-main-cart {
        height: calc(100vh - 45px);
      }



      .checkout-order-total {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .subtotal,
        .shipping {
          display: flex;
          width: 100%;
          justify-content: space-between;
          span {
            &:last-child {
              font-family: "Nunito Sans", sans-serif !important;
              font-weight: 700;
              color: #000;
            }
          }
        }
        .grand-total {
          display: flex;
          width: 100%;
          justify-content: space-between;
          border-top: solid 1px #d9d9d9;
          padding-top: 10px;
          margin-top: 15px;
          .gr-total {
            font-size: 16px;
          }
          .gr-t-price {
            font-family: "Nunito Sans", sans-serif !important;
            font-weight: 700;
            color: #000;
            font-size: 22px;
          }
        }
      }


    }
    .checkout-frame {
      display: flex;
      width: 100%;
      background-color: #fff;
      position: absolute;
      height: calc(100vh - 45px);
      overflow-y: auto;
      // margin-top: 43px;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        transition-duration: 0.3s;
        box-shadow: rgba(0, 0, 0, 30%) 0px 0px 6px inset;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        transition-duration: 0.3s;
        box-shadow: rgba(0, 0, 0, 30%) 0px 0px 6px inset;
        border-radius: 10px;
      }
      &:hover::-webkit-scrollbar-track {
        box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
      }
      &:hover::-webkit-scrollbar-thumb {
        box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
      }
      iframe {
        width: 100%;
        border: none;
      }
      .order-summery {
        width: 100%;
        .ant-collapse {
          border: none;
        }
        .ant-collapse-content {
          background-color: #fafafa;
        }
        .ant-collapse-header {
          padding: 13px 10px 13px 10px;
          border-radius: 0;
        }
        .ant-collapse-expand-icon {
          span {
            vertical-align: 0;
          }
        }
        .total-price-top {
          position: absolute;
          z-index: 2;
          font-family: "Nunito Sans", sans-serif !important;
          font-weight: 700;
          color: #000;
          font-size: 20px;
          right: 0;
          top: 0;
          padding: 8px 16px;
        }
        .ordered-products {
          width: 100%;
          .list-col {
            width: 100%;
            display: inline-flex;
            flex-direction: column;
            margin-bottom: 10px;
            &:last-child {
              padding-bottom: 0;
              border-bottom: none;
            }

            .prod-box {
              background-color: #fff;
              display: inline-flex;
              min-width: 100%;
              position: relative;
              border: solid 1px #d9d9d9;
              border-radius: 8px;
              padding: 10px;
              .prod-image {
                width: 65px;
                flex-basis: 65px;
                position: relative;
                transition: box-shadow 0.25s;
                flex-grow: 0;
                flex-shrink: 0;
                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
                .pro-qty {
                  position: absolute;
                  background: rgba(114, 114, 114, 0.9);
                  color: #fff;
                  height: 1.75em;
                  min-width: 1.75em;
                  border-radius: 1.75em;
                  display: flex;
                  font-size: 0.85rem;
                  justify-content: center;
                  align-items: center;
                  right: -5px;
                  top: -6px;
                  font-family: "Nunito Sans", sans-serif !important;
                }
              }
              .prod-name {
                display: flex;
                flex-grow: 1;
                margin-left: 25px;
                min-width: 0px;
                flex-direction: column;
                width: 100%;
                padding-right: 15px;
                .prod-title {
                  display: flex;
                  flex-direction: column;
                  .prod-name-inr {
                    font-family: "Nunito Sans", sans-serif !important;
                    font-weight: 700;
                    color: #000;
                    font-size: 0.85rem;
                    line-height: 18px;
                    display: -webkit-box;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                    overflow-wrap: normal;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                  }
                  .prod-veriants-inr {
                    font-family: "Nunito Sans", sans-serif !important;
                    font-weight: 700;
                    color: #000;
                    font-size: 0.85rem;
                    line-height: 18px;
                  }
                }
                .prod-price {
                  display: flex;
                  margin-top: 5px;
                  span {
                    font-family: "Nunito Sans", sans-serif !important;
                    font-weight: 800;
                    color: #000;
                    &.original-price {
                      color: #f00;
                      margin-left: 10px;
                      text-decoration-line: line-through;
                    }
                  }
                }
                .qty-rmv-btn {
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: 5px;
                  .remove-btn {
                    color: #000;
                    font-size: 12px;
                    text-decoration: underline;
                  }
                }
              }
              .prod-cart-btn {
                display: flex;
                flex-direction: column;
                margin-right: 0;
                a {
                  background-color: #010b40;
                  border-radius: 37px;
                  color: #fff;
                  cursor: pointer;
                  font-family: Nunito Sans, sans-serif !important;
                  font-size: 12px;
                  font-weight: 700;
                  padding: 6px 0;
                  min-width: 80px;
                  text-align: center;
                  &:hover {
                    background-color: #000;
                  }
                }
              }
            }
          }
        }
        .checkout-discount-section {
          // display: flex;
          width: 100%;
          gap: 15px;
          margin-top: 10px;
          padding-bottom: 20px;
          border-bottom: solid 1px #d9d9d9;
          margin-bottom: 20px;
          .ant-form {
            width: 100%;
            display: flex;
            gap: 10px;
            .ant-form-item {
              margin-bottom: 0;
            }
            .float-label {
              width: 100%;
              .ant-input {
                height: 45px;
                border-radius: 4px;
              }
            }
            .btn-primary {
              background-color: #010b40;
              border-color: #010b40;
            }
          }
        }
        .checkout-order-total {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 5px;
          .subtotal,
          .shipping {
            display: flex;
            width: 100%;
            justify-content: space-between;
            span {
              &:last-child {
                font-family: "Nunito Sans", sans-serif !important;
                font-weight: 700;
                color: #000;
              }
            }
          }
          .grand-total {
            display: flex;
            width: 100%;
            justify-content: space-between;
            border-top: solid 1px #d9d9d9;
            padding-top: 10px;
            margin-top: 15px;
            .gr-total {
              font-size: 16px;
            }
            .gr-t-price {
              font-family: "Nunito Sans", sans-serif !important;
              font-weight: 700;
              color: #000;
              font-size: 22px;
            }
          }
        }
        .customer-information {
          width: 100%;
          margin-top: 10px;
          padding: 0 10px;
          .ant-tabs-top {
            .ant-tabs-nav {
              &:before {
                display: none;
              }
            }
          }
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              width: auto;
              .ant-tabs-tab {
                box-shadow: none;
                width: auto;
                border: none;
                position: relative;
                padding-right: 15px;
                &:after {
                  content: "\f105";
                  font-family: "FontAwesome";
                  position: absolute;
                  right: 0;
                  font-size: 15px;
                }
                &:nth-child(2) {
                  &:after {
                    display: none;
                  }
                }
                &:hover {
                  text-decoration: underline;
                }
              }
              .ant-tabs-ink-bar {
                display: none;
              }
            }
          }
          .checkout-stage-one {
            width: 100%;
            margin-top: 20px;
            h2 {
              font-size: 1.15rem;
              font-weight: 700 !important;
              padding: 0;
            }
            .ant-form {
              margin-top: 15px;
            }
            .inf-column {
              display: flex;
              width: 100%;
              padding: 8px 0;
              .float-label {
                width: 100%;
                .ant-input-lg,
                .ant-select-selector {
                  height: 45px;
                  border-radius: 4px;
                }
              }
            }
            .inf-button {
              width: 100%;
              margin-top: 10px;
              margin-bottom: 15px;
              button {
                width: 100%;
                padding: 0.7rem 1rem;
                background-color: #010b40;
                border-color: #010b40;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;
                &:hover {
                  background-color: #000;
                }
              }
            }
          }
          .checkout-stage-two {
            width: 100%;
            margin-top: 20px;
            h2 {
              font-size: 1.15rem;
              font-weight: 700 !important;
              padding: 0;
            }
            .filed-detail {
              display: flex;
              flex-direction: column;
              border: solid 1px #d9d9d9;
              padding: 10px;
              border-radius: 4px;
              .contact-email {
                display: flex;
                flex-direction: column;
                position: relative;
                .contc-1 {
                  color: #737373;
                }
                .contact-2 {
                  strong {
                    font-family: "Nunito Sans", sans-serif !important;
                    font-weight: 700;
                  }
                }
                a {
                  position: absolute;
                  top: 0;
                  right: 10px;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
              .shipp-email {
                border-top: solid 1px #d9d9d9;
                padding-top: 10px;
                margin-top: 10px;
                a {
                  top: 6px;
                }
              }
            }
            .more-addresses {
              width: 100%;

              h2 {
                font-size: 1.15rem;
                font-weight: 700 !important;
                padding: 0;
              }
              .slick-slider {
                margin-left: -5px;
                margin-right: -5px;
                .slick-list {
                  .slick-track {
                    margin: 0;
                    .slick-slide {
                      padding: 0 5px;
                      // min-width: 256px;
                      min-width: 248px;
                    }
                  }
                }
              }
              .address-box {
                border: solid 1px #d9d9d9;
                min-height: 135px;
                border-radius: 4px;
                padding: 16px;
                position: relative;
                cursor: pointer;
                &.dflt {
                  border-color: #010b40;
                }
                .address-inner-detail {
                  display: flex;
                  width: 100%;
                  flex-direction: column;
                  font-family: "Nunito Sans", sans-serif !important;
                  font-weight: 700;
                  font-size: 0.854rem;
                  h4 {
                    font-size: 1rem;
                    font-weight: 700 !important;
                    color: #737373;
                  }
                }
                .edit-address {
                  position: absolute;
                  bottom: 16px;
                  right: 16px;
                  font-size: 16px;
                  display: flex;
                  align-items: center;
                  i {
                    cursor: pointer;
                    margin-right: 15px;
                  }
                }
              }
            }
            .select-shipping-rate {
              width: 100%;
              margin-top: 30px;
              .shipping-box {
                display: flex;
                flex-direction: column;
                border: solid 1px #d9d9d9;
                border-radius: 4px;
                .ant-radio-wrapper {
                  border-bottom: solid 1px #d9d9d9;
                  width: 100%;
                  padding: 12px 10px;
                  &:last-child {
                    border: none;
                  }
                  .ship-rates {
                    display: flex;
                    flex-direction: column;
                    width: 250px;
                    .small-text {
                      color: #737373;
                      font-size: 0.854rem;
                    }
                  }
                  .ship-rates-right {
                    position: absolute;
                    right: 10px;
                    top: 12px;
                    font-family: "Nunito Sans", sans-serif !important;
                    font-weight: 700;
                  }
                }
                &.style2 {
                  background-color: #ffffff;
                }
              }
            }
            .checkot-continue-btn {
              width: 100%;
              margin-top: 30px;
              margin-bottom: 20px;
              button {
                width: 100%;
                padding: 1rem 1rem;
                background-color: #010b40;
                border-color: #010b40;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;
                &:hover {
                  background-color: #000;
                }
              }
            }
            .gift-card-checkout {
              width: 100%;
              margin-top: 30px;
              h2 {
                font-size: 1.15rem;
                font-weight: 700 !important;
                padding: 0;
              }
              .checkout-discount-section {
                padding-bottom: 0;
                margin-bottom: 0;
                border: none;
              }
            }
            .select-cradit-card {
              width: 100%;
              margin-top: 30px;
              h2 {
                font-size: 1.15rem;
                font-weight: 700 !important;
                padding: 0;
                margin-bottom: 0;
              }
              p {
                color: #737373;
              }
              .filed-detail {
                padding-top: 15px;
                padding-bottom: 15px;
                .contact-email {
                  .contact-2 {
                    font-family: "Nunito Sans", sans-serif !important;
                    font-weight: 700 !important;
                    font-size: 16px;
                    i {
                      font-size: 18px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// Bioshop Checkout

.bio_shop_new_ift {
  width: 100%;
  margin-top: 70px;

  .bio_container_main {
    width: 100%;

    .bio_shop_header {
      display: flex;
      width: 100%;

      .bio_shop_header_left {
        display: inline-flex;
        flex-direction: column;

        .brand_logo {
          width: 100px;
          overflow: hidden;
          position: relative;
          border: solid 1px #ccc;
          height: 100px;
          border-radius: 50%;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .brand_name {
          text-transform: uppercase;
          font-family: Nunito Sans, sans-serif !important;
          font-size: 1rem;
          font-weight: 700;
          color: #f00;
          margin-top: 15px;
        }
      }
    }
  }
}

.bio_shop_main_ift {
  width: 100%;
  margin-top: 40px;

  .bio_shop_inr {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  }

  .bio_container_main {
    width: 100%;

    .bio_shop_header {
      min-width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      display: none;

      .bio_shop_header_left {
        display: flex;
        flex-direction: column;
        width: 17%;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 17%;

        .brand-descrp {
          display: flex;
          align-items: center;
          cursor: pointer;

          .brand_logo {
            width: 100px;
            overflow: hidden;
            position: relative;
            border: solid 1px #ccc;
            height: 100px;
            border-radius: 50%;

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .current-brand-name {
            text-transform: uppercase;
            font-family: Nunito Sans, sans-serif !important;
            font-size: 1rem;
            font-weight: 800;
            color: #000;
            margin-left: 15px;
            .view-profile-btn{
              color: #7b8994;
              text-transform: capitalize;
              font-size: 12px;
              font-weight: 700;
              &:hover{
                color: #000;
                text-decoration: underline;
              }
            }
          }
        }
        .brand_name {
          text-transform: uppercase;
          font-family: Nunito Sans, sans-serif !important;
          font-size: 1rem;
          font-weight: 700;
          color: #f00;
          margin-top: 15px;
        }
      }

      .bio_shop_header_center{
        display: flex;
        flex-grow: 1;
        width: 100%;
        min-width: 0;

        .bio_shop_cat_slider {
          width: 100%;
          background-color: #fff;
          z-index: 1;
          margin-top: 15px;
  
          .react-multi-carousel-list {
            margin-left: -12px;
            margin-right: -12px;
  
            .react-multi-carousel-track {
              .react-multi-carousel-item {
                padding-left: 12px;
                padding-right: 12px;
              }
            }
          }
          .react-multiple-carousel__arrow {
            &:before {
              background: #fff;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              line-height: normal;
              box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);
              font-size: 16px;
              color: #000 !important;
              margin-top: -20px;
            }
          }
  
          .react-multiple-carousel__arrow--right {
            right: calc(0% - 3px) !important;
          }
          .react-multiple-carousel__arrow--left {
            left: calc(0% - 3px) !important;
          }
  
          .carousel-items {
            display: inline-flex;
            flex-direction: column;
            width: 100%;
  
            a {
              .cat_height {
                height: 88px;
                position: relative;
                overflow: hidden;
                width: 100%;
                transition: box-shadow 0.25s;
                // box-shadow: 0px 4px 8px rgb(10 22 70 / 15%);
                border: solid 1px #ccc;
                border-radius: 8px;
                display: none;
  
                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
              }
  
              .circles {
                border-radius: 50% !important;
                border: 1px solid #ccc;
                padding: 2px;
                width: 65px;
                height: 65px;
              }
  
              span {
                font-weight: 800 !important;
                font-size: 14px;
                margin-bottom: 0;
                margin-top: 0;
                color: #000;
                height: 88px;
                position: relative;
                overflow: hidden;
                width: 100%;
                transition: box-shadow 0.25s;
                border: solid 1px #ccc;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                font-family: "Nunito Sans", sans-serif !important;
                text-align: center;
              }
  
              &.active span {
                color: #e80c5b;
              }
            }
          }
          &.sub-cat-slider {
            .react-multiple-carousel__arrow {
              &:before {
                margin-top: -40px;
              }
            }
            // height:0px;
            // overflow: hidden;
            -webkit-transition: height, 0.3s linear;
            -moz-transition: height, 0.3s linear;
            -ms-transition: height, 0.3s linear;
            -o-transition: height, 0.3s linear;
            transition: height, 0.3s linear;
  
            &.sub-cat-slider.open {
              height: 115px;
              -webkit-transition: height, 0.3s linear;
              -moz-transition: height, 0.3s linear;
              -ms-transition: height, 0.3s linear;
              -o-transition: height, 0.3s linear;
              transition: height, 0.3s linear;
            }
  
            .carousel-items {
              a {
                .cat_height {
                  height: 68px !important;
                }
                span {
                  font-weight: 700 !important;
                  line-height: 1.2rem;
                }
              }
            }
          }
        }



      }
    }

    .bio_shop_body {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 50px;
      .bioshop_filters_new{
        display: grid;
        width: 100%;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-column-gap: 24px;
        .post-type{
          display: flex;
          flex-direction: column;
          position: relative;
          .ant-select-lg{
            font-size: 14px;
            .ant-select-selector{
              border-color: #000;
            }
          }
          &.category-filter{
            .category-box{
              width: 100%;
              height: 40px;
              padding: 0 11px;
              position: relative;
              background-color: #fff;
              border: 1px solid #000;
              border-radius: 2px;
              display: flex;
              align-items: center;
              cursor: pointer;
              transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              // &:hover{
              //   border-color: #40a9ff;
              //   border-right-width: 1px;
              // }
              .anticon-down{
                height: 12px;
                color: rgba(0, 0, 0, 0.25);
                position: absolute;
                right: 11px;
                font-size: 12px;
              }
            }
            .category-box-all{
              position: absolute;
              background-color: #fff;
              border-radius: 3px;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
              margin-top: 3px;
              z-index: 99;
              left: 0;
              top: 70px;
                .all-inner{
                  height: 350px;
                  position: relative;
                  padding: 10px;
                  width: 500px;
                  display: flex;
                  .main-cat-left{
                    width: 40%;
                    display: flex;
                    flex-direction: column;
                    list-style-type: none;
                    overflow-y: auto;
                    padding: 0 10px;
                    border-right: solid 1px #ccc;
                    h3{
                      font-weight: 800 !important;
                      font-size: 16px;
                      padding: 8px 0 8px 0;
                      margin: 0;
                      line-height: 1;
                    }
                    .cat-filter-box{
                      padding: 0;
                      display: flex;
                      flex-direction: column;
                      margin-top: 5px;
                      gap: 10px;
                      button{
                        color: rgb(64, 64, 64);
                        font-size: 0.875rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        border: solid 1px #dce4ec;
                        background-color: #fff;
                        padding: 8px 10px;
                        border-radius: 0;
                        font-family: "Nunito Sans", sans-serif !important;
                        font-weight: 700;
                        text-transform: uppercase;
                        line-height: 1.25rem;
                        position: relative;
                        height: auto;
                        &:hover{
                          background-color: #e1e2e4;
                        }
                        &.active{
                          &:before{
                            position: absolute;
                            right: -4px;
                            top: -7px;
                            content: "\f00c";
                            font-family: Fontawesome;
                            z-index: 1;
                            font-size: 10px;
                            background: #e80c5b;
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            color: #fff;
                            font-weight: normal;
                          }
                        }
                      }
                    }
                    &::-webkit-scrollbar {
                      width: 6px;
                      height: 6px;
                    }
                    &::-webkit-scrollbar-track {
                      transition-duration: 0.3s;
                      box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
                      border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                      transition-duration: 0.3s;
                      box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
                      border-radius: 10px;
                    }
                    &:hover::-webkit-scrollbar-track {
                      box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
                    }
                    &:hover::-webkit-scrollbar-thumb {
                      box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
                    }
                  }
                  .main-cat-right{
                    width: 60%;
                    display: flex;
                    flex-direction: column;
                    list-style-type: none;
                    overflow-y: auto;
                    padding: 0 10px;
                    h3{
                      font-weight: 800 !important;
                      font-size: 16px;
                      padding: 8px 0 8px 0;
                      margin: 0;
                      line-height: 1;
                    }
                    .cat-filter-box{
                      padding: 0;
                      display: flex;
                      flex-wrap: wrap;
                      margin-top: 5px;
                      gap: 10px;
                      button{
                        color: rgb(64, 64, 64);
                        font-size: 0.875rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        border: solid 1px #dce4ec;
                        background-color: #fff;
                        padding: 8px 10px;
                        border-radius: 0;
                        font-family: "Nunito Sans", sans-serif !important;
                        font-weight: 700;
                        text-transform: uppercase;
                        line-height: 1.25rem;
                        position: relative;
                        height: auto;
                        &:hover{
                          background-color: #e1e2e4;
                        }
                        &.active{
                          &:before{
                            position: absolute;
                            right: -4px;
                            top: -7px;
                            content: "\f00c";
                            font-family: Fontawesome;
                            z-index: 1;
                            font-size: 10px;
                            background: #e80c5b;
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            color: #fff;
                            font-weight: normal;
                          }
                        }
                      }
                    }

                    &::-webkit-scrollbar {
                      width: 6px;
                      height: 6px;
                    }

                    &::-webkit-scrollbar-track {
                      transition-duration: 0.3s;
                      box-shadow: rgba(0, 0, 0, 30%) 0px 0px 6px inset;
                      border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                      transition-duration: 0.3s;
                      box-shadow: rgba(0, 0, 0, 30%) 0px 0px 6px inset;
                      border-radius: 10px;
                    }
                    &:hover::-webkit-scrollbar-track {
                      box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
                    }
                    &:hover::-webkit-scrollbar-thumb {
                      box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
                    }
                  }
                }
              
            }
          }
        }
      }

      .bio_shop_cat_slider {
        // &.sub-cat-slide{
        //   .react-multi-carousel-list{
        //     -webkit-transition: max-height 1s;
        //     -moz-transition: max-height 1s;
        //     -ms-transition: max-height 1s;
        //     -o-transition: max-height 1s;
        //     transition: max-height 1s;
        //     background: #e5feff;
        //     overflow: hidden;
        //     // "height: 0" not work with css transitions
        //     max-height: 0;
        //   }
        //   &.sub-category-class{
        //     .react-multi-carousel-list{
        //       max-height: 150px;
        //     }
        //   }
        // }

        // &.sub-cat-slide{
        //   .react-multi-carousel-list{
        //     height:0px;
        //     overflow: hidden;
        //     -webkit-transition:height, 0.3s linear;
        //     -moz-transition: height, 0.3s linear;
        //     -ms-transition: height, 0.3s linear;
        //     -o-transition: height, 0.3s linear;
        //     transition: height, 0.3s linear;
        //   }
        //   &.sub-category-class{
        //     .react-multi-carousel-list{
        //       height: 150px;
        //       -webkit-transition:height, 0.3s linear;
        //       -moz-transition: height, 0.3s linear;
        //       -ms-transition: height, 0.3s linear;
        //       -o-transition: height, 0.3s linear;
        //       transition: height, 0.3s linear;
        //     }
        //   }
        // }

        // position: -webkit-sticky;
        // position: -moz-sticky;
        // position: -o-sticky;
        // position: -ms-sticky;
        // position: sticky;
        // top: 0;
        background-color: #fff;
        z-index: 1;
        margin-top: 15px;
        display: none;

        .react-multi-carousel-list {
          margin-left: -12px;
          margin-right: -12px;

          .react-multi-carousel-track {
            .react-multi-carousel-item {
              padding-left: 12px;
              padding-right: 12px;
            }
          }
        }
        .react-multiple-carousel__arrow {
          &:before {
            background: #fff;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: normal;
            box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);
            font-size: 16px;
            color: #000 !important;
            // margin-top: -20px;
          }
        }

        .react-multiple-carousel__arrow--right {
          right: calc(0% - 3px) !important;
        }
        .react-multiple-carousel__arrow--left {
          left: calc(0% - 3px) !important;
        }

        .carousel-items {
          display: inline-flex;
          flex-direction: column;
          width: 100%;

          a {
            .cat_height {
              height: 88px;
              position: relative;
              overflow: hidden;
              width: 100%;
              transition: box-shadow 0.25s;
              // box-shadow: 0px 4px 8px rgb(10 22 70 / 15%);
              border: solid 1px #ccc;
              border-radius: 8px;
              display: none;

              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }

            .circles {
              border-radius: 50% !important;
              border: 1px solid #ccc;
              padding: 2px;
              width: 65px;
              height: 65px;
            }

            // span {
            //   font-family: "Nunito Sans", sans-serif !important;
            //   display: block;
            //   font-weight: 800 !important;
            //   font-size: 14px;
            //   margin-bottom: 0;
            //   margin-top: 10px;
            //   color: #000;
            //   display: none;
            // }
            span{
              font-weight: 800 !important;
              font-size: 14px;
              margin-bottom: 0;
              margin-top: 0;
              color: #000;
              height: 88px;
              position: relative;
              overflow: hidden;
              width: 100%;
              transition: box-shadow 0.25s;
              border: solid 1px #ccc;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
              font-family: "Nunito Sans", sans-serif !important;
              text-align: center;
            }

            &.active span {
              color: #e80c5b;
              border-color: #333;
            }
          }
        }
        &.sub-cat-slider {
          .react-multiple-carousel__arrow {
            &:before {
              margin-top: -40px;
            }
          }
          // height:0px;
          // overflow: hidden;
          -webkit-transition: height, 0.3s linear;
          -moz-transition: height, 0.3s linear;
          -ms-transition: height, 0.3s linear;
          -o-transition: height, 0.3s linear;
          transition: height, 0.3s linear;

          &.sub-cat-slider.open {
            height: 115px;
            -webkit-transition: height, 0.3s linear;
            -moz-transition: height, 0.3s linear;
            -ms-transition: height, 0.3s linear;
            -o-transition: height, 0.3s linear;
            transition: height, 0.3s linear;
          }

          .carousel-items {
            a {
              .cat_height {
                height: 68px !important;
              }
              span {
                font-weight: 700 !important;
                line-height: 1.2rem;
              }
            }
          }
        }
      }

      .bio_cat_images {
        width: 100%;
        .no-data-found {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-height: 400px;
        }

        .post-grid {
          margin-top: 30px;
          display: grid;
          grid-row-gap: 32px;
          grid-column-gap: 24px;
          grid-template-columns: repeat(4, minmax(0, 1fr));
          overflow: hidden !important;

          .post-image {
            position: relative;

            .image-box {
              position: relative;
              left: 1px;
              right: 1px;
              top: 1px;
              bottom: 1px;
              padding-bottom: 100%;

              span {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                overflow: hidden;
                margin: 0;
                display: block;
                cursor: pointer;
                border: 1px solid;
                border-color: #f6f6f6 #f3f3f3 #eee;
                border-radius: 10px;

                img {
                  display: block;
                  height: 100%;
                  width: 100%;
                  border-radius: 0px !important;
                  -o-object-fit: cover;
                  object-fit: cover;
                  -o-object-position: 50% 0;
                  object-position: 50% 0;
                }
                video {
                  display: block;
                  height: 100%;
                  width: 100%;
                  border-radius: 0px !important;
                  -o-object-fit: cover;
                  object-fit: cover;
                  -o-object-position: 50% 0;
                  object-position: 50% 0;
                }
              }
              &.play-button {
                i{
                  position: absolute;
                  right: 0;
                  margin: 0 auto;
                  z-index: 9;
                  text-align: center;
                  font-size: 70px;
                  cursor: pointer;
                  color: #FFF;
                  opacity: 0.8;
                  width: auto;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
            .cat-pro-detail {
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-top: 10px;
              font-family: "Nunito Sans", sans-serif !important;
              font-weight: 800;

              .cat-pro-title {
                display: flex;
                gap: 20px;
                position: relative;
                justify-content: space-between;
                span {
                  font-size: 14px;
                  &.bio-item-title{
                    flex: 1;
                  }
                }
                .inf-ref-fee-main{
                  display: flex;
                  flex-direction: column;
                  .bio-item-fee{
                    color: #F00;
                  }
                  .inf-item-fee{
                    color: #fe8300;
                  }
                }
              }
              .cat-pro-price {
                margin-top: 5px;
                span {
                  padding-right: 15px;
                  color: green;
                  &:last-child {
                    padding-right: 0;
                    color: #f00;
                    text-decoration-line: line-through;
                  }
                }
              }
            }

            &:after {
              content: "";
              padding-top: 100%;
              display: none;
            }
          }
          .content-loader {
            border: 4px solid #f3f3f3;
            border-radius: 50%;
            border-top: 4px solid #252627;
            width: 50px;
            height: 50px;
            margin: 20px 0;
            -webkit-animation: postSpin 2s linear infinite;
            animation: postSpin 2s linear infinite;
          }
          @keyframes postSpin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
      .bioshop-profile-page {
        max-width: 100%;
        margin-top: 20px;
        min-height: 500px;

        .brand-logo {
          max-width: 100%;
          display: flex;
          justify-content: center;

          .profile-logo-brnd {
            width: 150px;
            overflow: hidden;
            position: relative;
            border: solid 1px #ccc;
            height: 150px;
            border-radius: 50%;
            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
        .brand-detail {
          max-width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;

          h2 {
            font-weight: 700 !important;
            text-align: center;
          }
          p {
            text-align: center;
            font-size: 16px;
            font-family: SegoeUI_Medium;
          }
        }
      }

      .bioshop_links {
        max-width: 100%;
        margin-top: 20px;
        min-height: 500px;

        .all-links {
          display: flex;
          max-width: 600px;
          margin: 0 auto;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          display: flex;

          a {
            display: block;
            background: #010b40;
            border: solid 1px #010b40;
            color: #fff;
            text-align: center;
            padding: 12px 0;
            margin-bottom: 15px;
            border-radius: 8px;
            font-size: 16px;
            width: 100%;
            &:hover {
              background-color: #fff;
              color: #000;
            }
          }
        }
      }
    }
  }
}

.bio-image-modal {
  max-width: 960px !important;

  .bio-modal-left-area {
    max-width: 100%;

    .react-multi-carousel-list {
      position: static;
      .react-multiple-carousel__arrow {
        min-height: auto !important;

        &:before {
          color: #b9bdbb !important;
          font-size: 65px !important;
          line-height: 1;
        }
      }
      .react-multiple-carousel__arrow--right {
        right: -60px !important;
      }
      .react-multiple-carousel__arrow--left {
        left: -60px !important;
      }

      .react-multi-carousel-track {
        .react-multi-carousel-item {
          height: 620px;
        }
      }
    }

    .bio-modal-main-content-area {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .image-inner-box {
        position: relative;
        width: 50%;
        height: 100%;

        .img-tag-area {
          position: relative;
          height: 500px;
          overflow: hidden;
        }

        .tag-div-main {
          background-color: white;
          border-radius: 50%;
          color: #000;
          font-size: 11px;
          height: 20px;
          left: 45%;
          line-height: 20px;
          position: absolute;
          width: 20px;
          cursor: pointer;
          text-align: center;
          font-weight: bold;

          &.active-circle {
            background-color: red !important;
            color: #fff !important;
          }
        }

        .image-inner-media {
          width: 100%;
          height: 100%;
          object-fit: cover;
          // object-position: 50% 0;
          border: solid 1px #ccc;
          padding: 5px;
        }
        video{
          width: 100%;
          height: 100%;
          // object-fit: cover;
          // object-position: 50% 0;
          border: solid 1px #ccc;
          padding: 5px;
        }
        .rounded {
          border-radius: 0.25rem !important;
        }
      }
      .modal-content-right-area {
        width: 48%;

        .cat-pro-detail {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 10px;
          font-family: "Nunito Sans", sans-serif !important;
          font-weight: 800;

          .cat-pro-title {
            display: flex;
            gap: 20px;
            position: relative;
            justify-content: space-between;
            span {
              font-size: 14px;
              &.bio-item-title{
                flex: 1;
              }
              &.bio-item-fee{
                color: #F00;
              }
            }
          }
          .cat-pro-price {
            span {
              padding-right: 15px;
              color: green;
              &:last-child {
                padding-right: 0;
                color: #f00;
                text-decoration-line: line-through;
              }
            }
          }
        }
        .pro-cat-description {
          width: 100%;
          margin-top: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          margin-bottom: 20px;
          p {
            margin-bottom: 0;
          }
        }
        .cat-pro-buttons {
          display: flex;
          width: 100%;
          gap: 10px;
          margin-bottom:10px;
          span {
            flex-basis: 100%;
            background-color: #fff;
            border-color: #5e5e5e;
            padding: 10px 0;
            font-family: "Nunito Sans", sans-serif !important;
            border-radius: 2px;
            position: relative;
            padding-left: 15px;
            text-transform: uppercase;
            font-weight: 800;
            color: #5e5e5e;

            i {
              position: relative;
              font-size: 14px;
              margin-left: 2px;
            }
            &:hover{
              background-color: #000;
              color: #FFF;
              border-color: #FFF;
            }
          }
        }
      }
    }

   .modal-content-right-area span.bio-item-fee {
      font-size: 14px;
      color: #F00;
      font-family: "Nunito Sans", sans-serif !important;
      font-weight: 800;
  }
    .modal-content-right-area span.inf-item-fee{
      display: block;
      font-size: 14px;
      color: #fe8300;
      font-family: "Nunito Sans", sans-serif !important;
      font-weight: 800;
    }

    .bio-image-thumbnail {
      display: flex;
      width: 100%;
      gap: 15px;
      margin-top: 15px;
      justify-content: center;

      .bio-t-img {
        width: 70px;
        height: 100%;
        border: solid 1px #ccc;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          object-position: 50% 0;
        }
      }
    }
  }

  .modal-content {
    padding: 1rem 1rem;

    .modal-header {
      padding: 0;
      padding-bottom: 5px;
      margin-bottom: 5px;
      border: none;

      .close {
        padding: 4px 0.5rem;
        font-size: 2rem;
      }

      .modal-title {
        line-height: 14px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700 !important;
        margin-bottom: 0;
      }
    }
    .modal-body {
      padding: 0;
    }

    .inner-image-box {
      position: relative;
      margin-top: 32px;

      .space-grid-right {
        padding-right: 7px;

        img {
          border: solid 1px #ccc;
          padding: 2px;
          border-radius: 2px;
          width: 100%;
        }
      }

      .space-grid-left {
        padding-left: 5px;
        gap: 10px;

        .prod-name {
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          text-align: left;
          font-family: "SegoeUIBold";
        }
        .prod-sku {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-weight: 500;
          font-size: 14px;
          font-family: "SegoeUIBold";

          .prod-amount {
            color: green;
            display: flex;
            padding-right: 5px;

            .prod-discount {
              padding-left: 10px;
            }
          }
        }

        .prod-footer {
          display: flex;
          justify-content: space-between;
          width: 102%;
          font-weight: 500;
          font-size: 14px;
          font-family: "SegoeUIBold";
          align-items: end;
          margin-left: -4px;
          //   margin-right: -4px;

          .shop-button {
            background: #010b40;
            border-color: #010b40;
            font-size: 10px;
            padding: 10px 0px;
            text-transform: uppercase;

            .fa {
              display: flex !important;
              justify-content: center;
            }
          }

          .col-4 {
            padding: 0 4px;
          }
          .col-4:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
  &.video-modal-box{
    .bio-modal-left-area {
      .bio-modal-main-content-area{
        height: 100%;
        .image-inner-box{
          display: flex;
          .img-tag-area{
            width: 100%;
            height: 100%;
          }
        }
      }
      .bio-image-thumbnail{
        display: none;
      }
    }
  }
}
.your-copy-link {
  display: flex;
  justify-content: space-between;
  // padding: 5px;
  border: 1px solid black;
  // border-radius: 1em;

  .item-a {
    display: inline-block;
    padding: 10px;
    cursor: default;
    overflow: hidden;
    height: 33px;
    word-break: break-all;

    a {
      color: black;
      font-weight: bold;
    }
  }

  .item-b {
    padding: 10px;
    border-left: 1px solid black;
    cursor: pointer;
  }
}

.ant-notification {
  z-index: 999999999;
}

.bio_shop_header_right {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  position: absolute;
  top: -28px;
  right: 0;
}

.bio_shop_header_right  {
  button {
      background: #010b40;
      border:none;
      color:white;
      background-color: #010b40;
      color: #FFF;
      font-family: Nunito Sans, sans-serif !important;
      padding: 5px 20px;
      font-weight: 700;
      border-radius: 37px;
      font-size: 14px;
      align-self: flex-end;
    .fa {
      margin-right: 7px;
      font-size: 13px;
      &.active {
        color: red;
      }
    }
    &:hover {
      background-color: #000;
      color: #FFF;
  }
  &:focus {
    background-color: #000;
    color: #FFF;
}
}
}

.profile-modal{
  .cst-close{
    position: absolute;
    right: 4px;
    top: -2px;
    font-size: 20px;
    line-height: 1;
    cursor: pointer;
    z-index: 1;
    padding: 5px;
  }
  .ant-tabs-nav-list{
    width: 100%;
    .ant-tabs-tab{
      width: 50%;
      justify-content: center;
      .ant-tabs-tab-btn{
        font-size: 1.1rem;
        font-family: "SegoeUI_Medium";
        letter-spacing: 1px;
        &:hover{
          color: #000;
        }
      }
      &.ant-tabs-tab-active{
        .ant-tabs-tab-btn{
          color: #000;
        }
      }
      &:hover{
        color: #000;
      }
    }
    .ant-tabs-ink-bar{
      background-color: #000;
    }
  }

  .bioshop-profile-page {
    max-width: 100%;
    margin-top: 20px;
    min-height: 250px;

    .brand-logo {
      max-width: 100%;
      display: flex;
      justify-content: center;

      .profile-logo-brnd {
        width: 100px;
        overflow: hidden;
        position: relative;
        border: solid 1px #ccc;
        height: 100px;
        border-radius: 50%;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
    .brand-detail {
      max-width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      h2 {
        font-weight: 700 !important;
        text-align: center;
      }
      p {
        text-align: center;
        font-size: 16px;
        font-family: SegoeUI_Medium;
      }
    }
  }
  .bioshop_links {
    max-width: 100%;
    margin-top: 20px;
    min-height: 250px;

    .all-links {
      display: flex;
      max-width: 100%;
      margin: 0 auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      display: flex;
      .no-data-found{
        text-align: center;
      }

      a {
        display: block;
        background: #010b40;
        border: solid 1px #010b40;
        color: #fff;
        text-align: center;
        padding: 12px 0;
        margin-bottom: 15px;
        border-radius: 8px;
        font-size: 16px;
        width: 100%;
        &:hover {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }
}