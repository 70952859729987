.swal2-image {
  border-radius: 50%;
  margin: 1em auto 0em;
  border: solid 1px #ccc;
}
.swal2-actions {
  .swal2-cancel {
    margin-right: 10px;
  }
}
.account-container-main {
  width: 100%;
  display: inline-block;
  margin-bottom: 40px;

  .account-container-outer {
    width: 100%;
  }

  .account-inr-container {
    width: 1170px;
    margin: 40px auto;
    min-height: 700px;
    &.reset-page{
      width: 550px;
      min-height: 300px;
    }

    .account-sidebar {
      width: 28.54701%;
      margin-right: 0.85556%;
      float: left;
      background-color: #fff;
      box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
      border-radius: 8px;
      min-height: 640px;

      .sid-1 {
        padding: 30px;
        border-bottom: solid 1px #ccc;

        span {
          display: block;
          text-align: center;
        }

        .member-welcome {
          font-size: 14px;
          line-height: 18px;
          color: #7b8994;
          padding-bottom: 5px;
        }

        .member-name {
          font-family: "Bitter", serif;
          font-weight: 700;
          font-style: normal;
          color: #252525;
          font-size: 34px;
          line-height: 44px;
          padding-bottom: 15px;
        }

        .member-since {
          font-size: 14px;
          line-height: 18px;
          color: #7b8994;
        }

        .member-edit {
          margin-top: 15px;

          a {
            font-family: Nunito Sans, sans-serif !important;
            font-size: 16px;
            font-weight: 800;
            color: #252525;

            &:hover {
              text-decoration: underline;
            }

            i {
              padding-right: 5px;
            }
          }
        }
      }

      .sid-2 {
        padding: 15px 40px 10px 0;

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            width: 100%;

            a {
              display: block;
              width: 100%;
              padding-left: 38px;
              color: #7b8994;

              span {
                padding: 15px 0 15px 10px;
                line-height: 20px;
                width: 100%;
                border-bottom: solid 1px #ccc;
                color: #7b8994;
                display: block;
              }

              &:hover {
                color: #000;
              }

              &.active {
                border-left: solid 5px #010b40;
                padding-left: 33px;

                span {
                  color: #000;
                  font-weight: 800;
                  font-family: "SegoeUIBold";
                }
              }
            }

            &:last-child {
              a {
                span {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }

    .account-left-content {
      width: 70.55556%;
      float: left;
      background-color: #fff;
      box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
      border-radius: 8px;
      min-height: 640px;
      position: relative;

      h1 {
        width: 100%;
        font-size: 28px;
        font-family: "Bitter", serif !important;
        line-height: 36px;
        padding: 18px 30px 6px 30px;
        border-bottom: solid 1px #ccc;
        font-weight: 800;
        position: relative;
        .plus-address{
            position: absolute;
            right: 30px;
            display: flex;
            top: 20px;
            align-items: center;
            text-transform: uppercase;
            font-family: "Nunito Sans", sans-serif !important;
            font-weight: 800;
            &:focus{
                color: #000;
                background-color: transparent;
                border-color: #ccc;
            }
            i{
                padding-right: 10px;
            }
            &:hover{
                background-color: #000;
                color: #FFF;
                border-color: #000;
            }
        }
      }

      h3 {
        padding: 10px 30px 0 30px;
        font-family: "SegoeUIBold" !important;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      .account-detail {
        display: inline-block;
        padding: 0 30px 30px 30px;
        width: 100%;

        .pr-op-block {
          margin-bottom: 10px;
          width: 100%;
          box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
          border-radius: 8px;
          padding: 20px;

          &.pro-inf-block {
            padding-left: 0;
            padding-right: 0;
            box-shadow: none;
          }

          .profile-form-main {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .profile-form-col {
              flex: 0 0 48%;
              max-width: 48%;

              .ant-form-item {
                margin-bottom: 15px;
              }

              .ant-input-lg {
                font-size: 14px;
                border-color: #000;
                height: 40px;
              }

              .ant-select-selector {
                border-color: #000;
                font-size: 14px;
              }
            }

            .profile-form-textarea {
              flex: 0 0 100%;
              max-width: 100%;
              margin-bottom: 0;

              label {
                width: 100%;
              }

              .ant-input {
                border-color: #000;
              }
            }

            .profile-form-button {
              width: 100%;

              .ant-form-item {
                margin-bottom: 0;

                .ant-btn {
                  background-color: #010b40;
                  border-color: #010b40;
                  color: #fff;
                  font-family: Nunito Sans, sans-serif !important;
                  padding: 10px 35px;
                  font-weight: 700;
                  border-radius: 37px;
                  height: auto;
                  font-size: 16px;

                  &:hover {
                    background-color: #000;
                  }
                }
              }
            }
          }

          .ps-title {
            display: block;
            width: 100%;
            line-height: 20px;
            font-family: "SegoeUIBold" !important;
            font-size: 16px;
            margin-bottom: 10px;
          }

          .password-box {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .hide-pass {
              color: #252525;
              font-size: 16px;
            }

            .change-password {
              background-color: #010b40;
              color: #fff;
              font-family: Nunito Sans, sans-serif !important;
              padding: 10px 35px;
              font-weight: 700;
              border-radius: 37px;
              display: inline-block;
              font-size: 16px;
              cursor: pointer;

              &:hover {
                background-color: #000;
              }
            }
          }

          .inf-cret-main {
            display: inline-flex;
            min-width: 100%;
            align-items: center;

            .inf-cret-left {
              display: flex;
              flex-grow: 1;
              min-width: 0px;
              flex-direction: column;
              width: 100%;

              p {
                strong {
                  font-family: "SegoeUIBold";
                }
              }

              a {
                background-color: #010b40;
                color: #fff;
                font-family: Nunito Sans, sans-serif !important;
                padding: 10px 35px;
                font-weight: 700;
                border-radius: 37px;
                display: inline-block;
                font-size: 16px;
                cursor: pointer;
                max-width: 240px;
                text-align: center;

                &:hover {
                  background-color: #000;
                }
              }
            }

            .inf-cret-right {
              width: 88px;
              height: 88px;
              flex-basis: 88px;
              position: relative;
              overflow: hidden;
              transition: box-shadow 0.25s;
              padding: 4px;
              flex-grow: 0;
              flex-shrink: 0;

              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
                border-radius: 6px;
              }
            }
          }
        }

        .card-information-store {
          width: 100%;
          .add-card-detail {
            height: 45px;
            border: solid 1px #d9d9d9;
            display: flex;
            width: 100%;
            font-family: Nunito Sans, sans-serif !important;
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
            align-items: center;
            padding-left: 12px;
            margin: 10px 0;
            position: relative;
            cursor: pointer;
            i {
              margin-right: 10px;
            }
            .selected-cradit-card{
                position: absolute;
                right: 20px;
                i{
                    margin-right: 0;
                }
            }
          }
          .cd-detail-box {
            width: 100%;
            display: flex;
            padding: 10px 0;
            .ant-form-item{
                margin-bottom: 0;
            }
            &.cd-detail-box-gap {
              gap: 10px;
            }
            .float-label {
              width: 100%;
              .ant-form-item{
                margin-bottom: 0;
              }
              .ant-input-lg {
                height: 45px;
              }
              .ant-select-selector{
                height: 45px;
              }
              label{
                background-color: #FFF;
                width: 90%;
                &.label-float{
                    width: auto;
                }
              }
            }
            .cd-2col {
              width: 50%;
            }
            .ant-checkbox-inner {
              width: 18px;
              height: 18px;
            }
            button {
                background-color: #010b40;
                border-color: #010b40;
                color: #fff;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;
                border-radius: 37px;
                display: inline-block;
                font-size: 16px;
                cursor: pointer;
                width: 100%;
                &:hover{
                    background-color: #000;
                }
            }
          }
          .billing-address-store {
            width: 100%;
            margin-top: 10px;
          }
          .address-list{
            width: 100%;
            display: grid;
            gap: 15px;
            margin-top: 15px;
            grid-template-columns: repeat(1, 1fr);
            .address-box{
                width: 100%;
                border: solid 1px #d9d9d9;
                border-radius: 4px;
                min-height: 140px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                &.dft-box{
                    background-color: #fafafa;
                    border-color: #000;
                }
                h4{
                    font-size: 1rem;
                    font-weight: 700 !important;
                    color: #737373;
                }
                .ship-address-iner{
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    font-family: "Nunito Sans", sans-serif !important;
                    font-weight: 700;
                }
                .ship-btns{
                    display: flex;
                    gap: 10px;
                    margin-top: 10px;
                    button{
                        background-color: #010b40;
                        border-color: #010b40;
                        color: #fff;
                        border-radius: 4px;
                        min-width: 80px;
                        &:hover{
                            background-color: #000;
                        }
                    }
                }
                .ant-checkbox-wrapper{
                    position: absolute;
                    top: 16px;
                    right: 16px;
                }
            }
          }
        }
      }

      .favourite-brands-container {
        width: 100%;
        padding: 0;

        .ant-tabs {
          .ant-tabs-nav {
            &::before {
              display: none;
            }
            .ant-tabs-nav-wrap {
              border-bottom: solid 1px #ccc;
              padding: 0 30px 13px 30px;
              .ant-tabs-nav-list {
                .ant-tabs-tab {
                  margin: 0;
                  padding: 8px 20px;
                  color: #7b8994;
                  font-size: 1rem;
                  font-family: Nunito Sans, sans-serif !important;
                  font-weight: 700;
                  &:hover {
                    color: #000;
                  }

                  &.ant-tabs-tab-active {
                    background-color: #010b40;
                    border-radius: 2rem;
                    .ant-tabs-tab-btn {
                      color: #fff;
                      text-shadow: none;
                    }
                  }
                }
                .ant-tabs-ink-bar {
                  display: none;
                }
              }
            }
          }
        }
      }

      .favourite-brands {
        width: 100%;
        display: grid;
        grid-row-gap: 20px;
        grid-column-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        padding: 10px 30px;

        .ant-empty-normal {
          position: absolute;
          left: 0;
          right: 0;
          height: 100%;
        }

        .indv-brand {
          a {
            display: inline-flex;
            flex-direction: column;
            width: 100%;
            cursor: pointer;

            .brand-boxes {
              background-color: #fff;
              border-radius: 6px;
              display: inline-flex;
              box-shadow: 0px 0px 8px rgba(10, 22, 70, 0.15);
              min-width: 100%;
              align-items: center;
              position: relative;

              .brnd-fav {
                position: absolute;
                right: 20px;
                font-size: 18px;
                padding: 5px;

                .fa-heart {
                  color: #e80c5b;
                }

                .fa-times {
                  display: none;
                  font-size: 20px;
                }

                &:hover {
                  .fa-times {
                    display: block;
                  }

                  .fa-heart {
                    display: none;
                  }
                }
              }

              .brand-boxes-logos {
                width: 88px;
                height: 88px;
                flex-basis: 88px;
                position: relative;
                overflow: hidden;
                transition: box-shadow 0.25s;
                padding: 4px;
                flex-grow: 0;
                flex-shrink: 0;

                img,
                video {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                  border-radius: 6px;
                }
              }

              .brand-user {
                display: flex;
                flex-grow: 1;
                margin-left: 10px;
                min-width: 0px;
                flex-direction: column;
                width: 100%;
                padding-right: 15px;

                .brnd-user-title {
                  display: flex;
                  flex-direction: column;

                  .brnd-name-title {
                    font-family: "Nunito Sans", sans-serif !important;
                    font-weight: 700;
                    color: #000;
                  }
                }

                .ft-2 {
                  display: flex;
                  align-items: center;

                  span {
                    font-size: 14px;
                    font-weight: 800;
                    font-family: Nunito Sans, sans-serif !important;
                    line-height: 20px;
                    color: #e80c5b;
                  }
                }
                .ft-3 {
                  display: flex;

                  .brnd-name-descrp {
                    font-size: 12px;
                    line-height: 16px;
                    color: #7b8994;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                }
              }
              .add-remove-button {
                display: flex;
                flex-direction: column;
                margin-right: 10px;
                span {
                  text-align: center;
                  font-size: 12px;
                  background-color: #b02a37;
                  color: #fff;
                  margin-bottom: 10px;
                  min-width: 60px;
                  padding: 2px 0;
                  border-radius: 2px;
                  font-family: "SegoeUI_Medium";
                  &:last-child {
                    margin-bottom: 0;
                    background-color: #010b40;
                  }
                  &:hover {
                    background-color: #000;
                  }
                }
                &.rmv-2 {
                  span {
                    &:first-child {
                      background-color: #b02a37;
                    }
                  }
                }
              }
            }
          }
        }
        .antd-loading-ift {
          width: 100%;
          min-height: 100%;
          display: flex;
          position: absolute;
          left: 0;
          top: 0;
          justify-content: center;
          align-items: center;
        }
      }

      .purchase-content-main {
        width: 100%;
        padding: 20px 30px 20px 30px;
      }

      .purchases-filter {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;

        .ant-picker-large {
          border-color: #000;
          height: 40px;

          .ant-picker-input > input {
            font-size: 14px;
            height: 40px;

            &::placeholder {
              color: #000;
            }
          }
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .ant-btn {
          background-color: #010b40;
          border-color: #010b40;
          color: #fff;
          font-family: Nunito Sans, sans-serif !important;
          padding: 0 35px;
          font-weight: 700;
          border-radius: 4px;
          font-size: 16px;
          height: 40px;

          &:hover {
            background-color: #000;
          }
        }
      }

      .purchase-data {
        width: 100%;

        .table-responsive {
          .table {
            th {
              font-family: "SegoeUIBold";
            }
          }
        }
      }

      .wallet-content-main {
        width: 100%;
        padding: 20px 0;

        .wallet-row-1 {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-left: 30px;
          padding-right: 30px;
          flex-wrap: wrap;

          .inr-box-main {
            display: inline-flex;
            min-height: 100%;
            align-items: center;

            .wallet-icon {
              width: 50px;
              flex-basis: 50px;
              position: relative;
              overflow: hidden;
              flex-grow: 0;
              flex-shrink: 0;
              margin-right: 15px;
              .filter-gray {
                filter: invert(76%) sepia(11%) saturate(6%) hue-rotate(315deg)
                  brightness(96%) contrast(81%);
              }
            }

            .wallet-data {
              display: flex;
              flex-grow: 1;
              margin-left: 10px;
              min-width: 0px;
              flex-direction: column;
              width: 100%;
              padding-right: 15px;

              .wallet-detail {
                display: flex;
                flex-direction: column;
              }
              .withdraw {
                display: flex;
              }
            }
          }

          .balance-box {
            flex: 0 0 48%;
            max-width: 48%;
            min-height: 100px;
            box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
            border-radius: 4px;
            padding: 10px 20px;
            display: flex;
            flex-direction: column;

            .box-title {
              font-family: "SegoeUI_Medium";
              font-size: 14px;
              color: #7b8994;
            }
            .box-ammount {
              font-family: Nunito Sans, sans-serif !important;
              color: #252525;
              font-weight: 700;
              font-size: 34px;
              span {
                font-size: 14px;
              }
            }
            .withdraw {
              a {
                font-size: 12px;
                background-color: #010b40;
                margin-right: 10px;
                color: #fff;
                padding: 5px 12px;
                border-radius: 2px;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;
                &:last-child {
                  margin-right: 0;
                  background-color: #e9e9e9;
                  color: #000;
                  display: none;
                  &:hover {
                    color: #fff;
                  }
                }
                &:hover {
                  background-color: #000;
                }
              }
            }
          }
          &.wallet-box-gap {
            .balance-box {
              margin-bottom: 25px;
            }
          }
        }
        .wallet-row-2 {
          width: 100%;
          margin-top: 20px;
          padding-left: 30px;
          padding-right: 30px;

          .wallet-header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            h3 {
              padding-left: 0;
            }
            .wallet-header-right {
              display: flex;

              .ant-picker-range {
                margin-right: 10px;
              }
              .ant-btn-primary {
                background-color: #010b40;
                border-color: #010b40;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;
                font-size: 12px;
                &:hover {
                  background-color: #000;
                  border-color: #000;
                }
              }
            }
          }
          .purchase-data {
            .table-responsive-lg {
              .table {
                th {
                  font-size: 12px;
                  text-transform: uppercase;
                  font-family: "SegoeUIBold";
                }
                td {
                  font-family: "SegoeUIBold";
                  font-size: 12px;
                  .status-active {
                    color: green;
                  }
                  .status-deleted {
                    color: red;
                  }
                  .pro-name {
                    display: flex;
                    min-width: 100%;
                    position: relative;

                    .pro-boxes-logos {
                      width: 55px;
                      height: 55px;
                      flex-basis: 55px;
                      position: relative;
                      overflow: hidden;
                      transition: box-shadow 0.25s;
                      padding: 0;
                      flex-grow: 0;
                      flex-shrink: 0;

                      img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 6px;
                      }
                    }
                    .brand-user-pro {
                      display: flex;
                      flex-grow: 1;
                      margin-left: 10px;
                      min-width: 0px;
                      flex-direction: column;
                      width: 100%;
                      font-family: "SegoeUIBold";
                      color: #000;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
        .withdraw-request {
          width: 100%;
          margin-top: 20px;

          .favourite-brands-container {
            .ant-tabs .ant-tabs-nav {
              .ant-tabs-nav-wrap {
                padding-top: 13px;
                border-top: solid 1px #ccc;
              }
            }
            .purchase-data {
              padding: 0 30px;
              .table-responsive-lg {
                .table {
                  th {
                    font-size: 12px;
                    text-transform: uppercase;
                    font-family: "SegoeUIBold";
                  }
                  td {
                    font-family: "SegoeUIBold";
                    font-size: 12px;
                    .status-success {
                      color: green;
                    }
                    .status-failed {
                      color: red;
                    }
                    .status-pending {
                      color: #212529;
                    }
                    .status-process {
                      color: #3e3c07;
                    }
                    .pro-name {
                      display: flex;
                      min-width: 100%;
                      position: relative;

                      .pro-boxes-logos {
                        width: 55px;
                        height: 55px;
                        flex-basis: 55px;
                        position: relative;
                        overflow: hidden;
                        transition: box-shadow 0.25s;
                        padding: 0;
                        flex-grow: 0;
                        flex-shrink: 0;

                        img {
                          display: block;
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                          object-position: center;
                          border-radius: 6px;
                        }
                      }
                      .brand-user-pro {
                        display: flex;
                        flex-grow: 1;
                        margin-left: 10px;
                        min-width: 0px;
                        flex-direction: column;
                        width: 100%;
                        font-family: "SegoeUIBold";
                        color: #000;
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.w-98 {
  width: 98%;
}

@media only screen and (max-width: 1270px) {
  .account-container-main {
    .account-inr-container {
      width: 100%;
      min-height: initial;
    }
  }
}

@media only screen and (max-width: 990px) {
  .account-container-main {
    margin-bottom: 20px;

    .account-inr-container {
      margin: 20px auto;

      .account-sidebar {
        min-height: initial;
        float: none;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: none;

        .sid-1 {
          padding: 10px 10px 10px 10px;
          border-top: solid 1px #ccc;

          .member-name {
            font-size: 20px;
            line-height: 26px;
            padding-bottom: 6px;
          }

          .member-edit {
            display: none;
          }

          .member-welcome {
            display: none;
          }

          span {
            text-align: left;
          }
        }

        .sid-2 {
          display: none;
        }
      }

      .account-left-content {
        min-height: initial;
        float: none;
        width: 100%;

        h1 {
          font-size: 18px;
          line-height: 24px;
          padding: 10px 15px 6px 15px;
        }

        h3 {
          padding: 10px 15px 0 15px;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 15px;
        }

        .account-detail {
          padding: 0 20px 15px 20px;

          .pr-op-block {
            padding: 0px;
            box-shadow: none;
            &.pro-inf-block {
              padding: 10px 0 0px 0;
            }

            .profile-form-main {
              .profile-form-button {
                .ant-form-item {
                  .ant-btn {
                    padding: 7px 20px;
                    font-size: 14px;
                    font-weight: 600;
                  }
                }
              }
            }

            .password-box {
              .change-password {
                padding: 7px 20px;
                font-size: 14px;
                font-weight: 600;
              }
            }

            .inf-cret-main {
              .inf-cret-left {
                a {
                  padding: 7px 20px;
                  font-size: 14px;
                  font-weight: 600;
                  max-width: fit-content;
                }
              }
            }
          }
        }
        .purchases-filter {
          .ant-btn {
            padding: 7px 20px;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .favourite-brands-container {
          .ant-tabs {
            .ant-tabs-nav {
              .ant-tabs-nav-wrap {
                padding: 0 15px 10px 15px;
              }
            }
          }
          .favourite-brands {
            padding-left: 20px;
            padding-right: 20px;
            .indv-brand {
              a {
                .brand-boxes {
                  .brand-user {
                    .ft-2 {
                      span {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .purchase-content-main {
          padding-left: 20px;
          padding-right: 20px;
        }
        .wallet-content-main {
          .wallet-row-1 {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
        .wallet-content-main {
          .wallet-row-2 {
            padding-left: 20px;
            padding-right: 20px;
          }
          .withdraw-request {
            .favourite-brands-container {
              .purchase-data {
                padding: 0 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .w-98 {
    width: 100%;
  }
  .account-container-main {
    .account-inr-container {
      .account-left-content {
        .purchase-content-main {
          padding: 15px 15px 20px 15px;
        }
        .account-detail {
          .pr-op-block {
            .profile-form-main {
              .profile-form-col {
                flex: 0 0 100%;
                max-width: 100%;
              }
            }
            .inf-cret-main {
              .inf-cret-right {
                display: none;
              }
            }
          }
        }
        .purchases-filter {
          .ant-space-vertical {
            &.w-98 {
              margin-bottom: 10px;
              margin-right: 10px;
            }
          }
        }
        .favourite-brands {
          grid-template-columns: repeat(1, 1fr);
          padding: 10px 20px;
        }
        .wallet-content-main {
          .wallet-row-1 {
            flex-direction: column;

            .balance-box {
              flex: 0 0 100%;
              max-width: 100%;
              margin-bottom: 20px;
              min-height: 80px;
              &:last-child {
                margin-bottom: 0;
              }
              .box-ammount {
                font-size: 28px;
              }
            }
          }
          .wallet-row-2 {
            .wallet-header {
              flex-direction: column;
              align-items: flex-start;
              margin-bottom: 10px;
            }
          }
        }
      }
      &.reset-page{
        width: 100%;
      }
    }

    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 370px) {
  .account-container-main {
    .account-inr-container {
      .account-left-content {
        .favourite-brands-container {
          .favourite-brands {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
        .account-detail {
          padding-left: 10px;
          padding-right: 10px;
        }
        .purchase-content-main {
          padding-left: 10px;
          padding-right: 10px;
        }
        .wallet-content-main {
          .wallet-row-1 {
            padding-left: 10px;
            padding-right: 10px;
          }
          .wallet-row-2 {
            padding-left: 10px;
            padding-right: 10px;
          }
          .withdraw-request {
            .favourite-brands-container {
              .purchase-data {
                padding-left: 10px;
                padding-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
